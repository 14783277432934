import React from 'react';
import Advisor from './Advisor';
import ContactRocket from './ContactRocket';
import Features from './Features';
import Team from './Team';


const Rocket = () => {
    return (
        <div className='bg-black'>
            <Features></Features>
            <Team></Team>
            <Advisor/>
            <ContactRocket/>
            
            
        
        </div>
    );
};

export default Rocket;