import React, { useRef } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import emailjs from '@emailjs/browser';

const Rockets = () => {
    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();
        console.log(form.current)
        emailjs.sendForm('service_x8q2r0a', 'template_ptmkk8h', form.current, 'uwgWYqC_idd4e-MHv')
            .then((result) => {
                console.log(result.text);
                toast.success("Email Successfully sent")
                form.current.reset()
                
            }, (error) => {
                toast.error(error.text);
            });
    };

    return (
        
            <div className='pb-60'>
                <form ref={form} onSubmit={sendEmail}>
                <div class='lg:flex lg:justify-evenly lg:mt-10  lg:ml-[156px]'>
                    <div className='space-y-11 lg:w-full'>
                        <input type="text" required name="user_name" placeholder="name" class="input w-full max-w-md bg-[#262837]" /><br />
                        <input type="email" required name="email" placeholder="email" class="input w-full max-w-md bg-[#262837]" /><br />
                        <input type="text" required name="address" placeholder="Adress of company" class="input w-full max-w-md bg-[#262837] py-10" /><br />

                        <select name="location" class="select w-full max-w-md bg-[#262837]">
                            <option disabled selected>Launch Location</option>
                            <option className='bg-[#95060E] '>India</option>
                        </select><br />

                        <select name="playload" class="select w-full max-w-md bg-[#262837]">
                            <option disabled selected>Playload Mass</option>
                            <option className='bg-[#95060E] '>5 Kg</option>
                            <option className='bg-[#95060E] '>10-12 Kg</option>
                            <option className='bg-[#95060E] '>12-15 Kg</option>

                        </select><br />


                        <select name="site" class="select w-full max-w-md bg-[#262837]">
                            <option disabled selected>Preferred site of Payload Integration</option>
                            <option className='bg-[#95060E] '>launchpad</option>

                        </select><br />


                    </div>
                    <div className='w-full mt-10'>
                        <input type="text" required name="company" placeholder="Company name" class="input w-full max-w-md bg-[#262837]" /><br />
                        <input type="text" required name="contact" placeholder="Contact No" class="input w-full max-w-md bg-[#262837] mt-10" /><br />
                        <input type="text" required name="purpose" placeholder="Pupose of contact" class="input w-full max-w-md bg-[#262837] mt-10" /><br />

                        <select name="Orbital_Inclination" value="launch" class="select w-full max-w-md bg-[#262837] mt-10">
                            <option disabled selected>Orbital Inclination</option>
                            <option className='bg-[#95060E] '>SSO</option>
                            <option className='bg-[#95060E] '>50 deg to SSO</option>
                            <option className='bg-[#95060E] '>0-50 deg</option>

                        </select><br />

                        <select name="Orbital_Altitude" class="select w-full  max-w-md bg-[#262837] mt-10">
                            <option disabled selected>Orbital Altitude</option>
                            <option className='bg-[#95060E] '>10-12 km</option>
                            <option className='bg-[#95060E] '>12-20 Km</option>
                            <option className='bg-[#95060E] '>20-25 Km</option>

                        </select><br />



                    </div>

                </div>
                <div className='flex justify-center'>
                    <button type="submit" class="btn btn-sm text-white mt-32 bg-black border border-[#95060E] w-40 ">Submit</button>
                </div>
                </form>
                <ToastContainer></ToastContainer>
            </div>
       

    );
};

export default Rockets;