import React from 'react';
import img1 from '../Images/Lokesh k s.jpeg'
import img2 from '../Images/Mukul Manohar.jpeg'

const Advisor = () => {
    return (
        <div>
           <div className='mt-5 bg-white pb-20'>
            <div className='text-center pt-20'>
                <h1 className='text-3xl font-Roboto font-bold'>Our Advisors</h1>
            </div>
            <div className='flex flex-col lg:flex-row justify-center items-center lg:justify-evenly mt-28'>
            <div className="card w-60 md:w-96 bg-base-100 shadow-2xl">
                                <figure className="px-10 pt-10">
                                    <img src={img1} alt="mukes" className="rounded-3xl w-[150px] md:w-[200px] shadow-2xl" />
                                </figure>
                                <div className="card-body items-center text-center">
                                    <h2 className="card-title">Lokesh k s</h2>
                                    <p className='md:text-lg text-sm'>Advisor</p>
                                    <div className="card-actions">
                                    <p className='md:text-base text-xs'> Your tomorrow may never come. Today is the pupil of yesterday. Each day of your life, as soon as you open your eyes in the morning, you can square away for a happy and successful day.</p>
                                    </div>
                                </div>
                            </div>
            
            </div>
         
        </div> 
        </div>
    );
};

export default Advisor;