import React from 'react';
import whiteimg from '../Images/whiteimg.png'
const WWdo = () => {
  return (
    <div className=' min-h-screen bg-black'>

      <div className=" flex flex-col lg:flex-row gap-40 lg:pt-40 pb-10">
        <img src={whiteimg} className="lg:w-[348px] h-[456px] lg:ml-52 rounded shadow-2xl px-10 lg:px-0" />
        <div className='my-auto px-5 lg:px-0'>
          <h1 className="text-4xl font-Opensans font-bold text-white">We are always on hunt for<br></br>
Fresh Talent</h1>
          <p className="py-6 text-white font-Opensans text-base">We are always looking for fresh talent to<br/> support us and to grow with us together.<br/> 
Interested to work with us ?<br/> 
Send your resume to Careers@lakshyaspace.in</p>
        </div>
      </div>

    </div>
  );
};

export default WWdo;