import React from 'react';
import img from '../Images/satelite.jpg'

const FeatureCuba = () => {
    return (
        <div className='bg-white pb-32 px-3 lg:px-0'>
         <div className='grid grid-cols-1 md:grid-cols-2 md:mx-10 mx-3 gap-10 '>
                    <div>
                        <img src={img} className='h-[300px] md:h-[500px] w-full mt-10' alt="" />
                    </div>
                    <div>
                        <p className='font-abeezee text-sm md:text-2xl mt-3 md:mt-32'>ArduSat is an Arduino based nanosatellite, based on the CubeSat standard. It contains a set of Arduino boards and sensors. ArduSat is the first satellite which will provide such open access to the general public to space. The general public will be allowed to use these Arduinos and sensors for their own creative purposes while they are in space It is one of several crowdfunded satellites launched during the 2010s.</p>
                    </div>
                </div>
    <div className='flex flex-col lg:flex-row justify-between mt-32'>
        <div className=' ml-3 md:ml-24' >
            <h1 className='font-Roboto text-xl md:text-2xl'>
                Features
            </h1>
            <h1 className='text-[#EA1C27] text-2xl md:text-4xl mt-5 md:mt-20 font-Poppins'>
                We are constantly <br/>working on finding <br/>ways to improve<br/> our products
            </h1>
            </div>
            <div className='grid grid-cols-2 gap-10 md:gap-20 lg:mr-32 mt-10 lg:mt-0'>
                <div>
                    <h1 className="text-xl md:text-2xl font-Poppins">
                        Art Direction
                        <p className='text-xs mt-2 md:mt-0'>
                            Am soluta nobis est eligendi <br/>optio cumque nihil impedit<br/> quo minus id quod
                        </p>
                    </h1>
                </div>

                <div>
                <h1 className='text-xl md:text-2xl font-Poppins'>
                        Web Design
                        <p className='text-xs mt-2 md:mt-0'>
                            Am soluta nobis est eligendi <br/>optio cumque nihil impedit<br/> quo minus id quod
                        </p>
                    </h1>
                </div>

                <div>
                <h1 className='text-xl md:text-2xl font-Poppins'>
                        Marketing
                        <p className='text-xs mt-2 md:mt-0'>
                            Am soluta nobis est eligendi <br/>optio cumque nihil impedit<br/> quo minus id quod
                        </p>
                    </h1>
                </div>

                <div>
                <h1 className='text-xl md:text-2xl font-Poppins'>
                        Photography
                        <p className='text-xs mt-2 md:mt-0'>
                            Am soluta nobis est eligendi <br/>optio cumque nihil impedit<br/> quo minus id quod
                        </p>
                    </h1>
                </div>

              
            </div>
        
    </div>
</div>
    );
};

export default FeatureCuba;