import React from 'react';


const Banner2 = () => {
    return (
        <div class="hero bg-[url('/src/Pages/Home/Banner/images/International.png')] bg-black bg-cover bg-no-repeat">
        <div class=" md:py-80"></div>
        <div class="text-start text-white  md:mt-32 ">
          <div>
        <h1 class='  font-serif text-center mb-5 md:mb-10 text-4xl lg:text-[100px]  mt-[-25%]'>
            Why US?
        </h1>
        <h1 class='text-4xl font-Oswald md:text-5xl ml-5 md:ml-10 pb-10 md:pb-0'>
            FASTER, AFFORDABLE, RELIABLE...
        </h1>


            
          </div>
        </div>
      </div>
    );
};

export default Banner2;