import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../Navbar/logo 1.png'
import { AiOutlineMenu } from 'react-icons/ai'
import { ImCross } from 'react-icons/im'
import { useState } from 'react';
import '../../../App.css'

const Navbar = ({ showDD, setShowDD }) => {
  const [drop, setDrop] = useState(false)
  const [dropdown, setDropdown] = useState(false)
  const handledrop = () => {
    if (!drop || !showDD) {
      console.log('hitted here')
      setShowDD(true)
      setDrop(true)

    }
    else {
      console.log('hitted handle drop false')
      setShowDD(true)
      setDrop(false)
    }
  }
  const handledropdown = () => {
    if (!dropdown) {
      console.log('hitted handle drop d true')
      setShowDD(true)
      setDropdown(true)
    }
    else {
      console.log('hitted handle drop d false')
      setShowDD(true)
      setDropdown(false)
    }
  }
  return (
    <div className='fixed w-full top-0 z-50'>
      <div class=" flex justify-between items-center bg-[#8091B0] 2xl:px-[2.5rem] ">
        <Link to='/'> <img className='w-[200px] md:w-[270px] h-[63px]' src={logo} /></Link>
        <div>


          <div class="navbar  hidden lg:block">
            <div class="navbar-center">
              <ul class="menu menu-horizontal p-0 font-sans text-white">
                <li><Link to='/'> Home</Link></li>
                <li><Link to='/about' >About</Link></li>

                <li tabindex="0">
                  <a>
                    Division
                    <svg class="fill-current" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z" /></svg>
                  </a>
                  <ul class="p-2 bg-white text-black">
                    <li><Link to='/rocket'  >Rocket</Link></li>
                    <li><Link to='/dronetechnology' >Drone Technology</Link></li>
                    <li><Link to='/cubasetnavbar' >Cubaset</Link></li>
                  </ul>
                </li>
                <li><Link to='/career' >Career</Link></li>
                <li><Link to='/contact' >Contact</Link></li>
                <li><Link to='/news' >News Room</Link></li>
                <li className='bg-[#EA1C27]  rounded-xl '><Link to='/launch' >Launch With US</Link></li>
              </ul>
            </div>

          </div>
          <div className='block lg:hidden'>
            <button onClick={handledrop} className='text-[1.5rem] mr-2'>
              <AiOutlineMenu />
            </button>
            <ul style={{ zIndex: '100' }} tabindex={0} className={`text-sm font-thin font-serif md:text-base fixed h-full right-0 top-0 p-5 overflow-auto shadow bg-black ${!showDD && 'hidden'} text-white w-[45%] md:w-[25%] space-y-5  ${drop ? 'block' : 'hidden'}`}>
              <button onClick={handledrop} className='text-[1rem] absolute right-0 pr-5'>
                <ImCross />
              </button>
              <li onClick={handledrop} className='hover:border-b pb-2 border-white '><Link to='/'> Home</Link></li>
              <li onClick={handledrop} className='hover:border-b pb-2 border-white '> <Link to='/about' >About</Link></li>
              <li className='hover:border-b pb-2 border-white' onClick={handledropdown}>
                <a tabindex={1} className='hover:cursor-pointer flex items-center ' >
                  Division
                  <svg className="fill-current " xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z" /></svg>
                </a>
                <ul tabindex={1} className={`p-2  bg-black text-white ${dropdown ? 'block' : 'hidden'}`}>
                  <li onClick={handledrop} className='hover:border-b pb-2 border-white'> <Link to='/rocket'  >Rocket</Link></li>
                  <li onClick={handledrop} className='hover:border-b pb-2 border-white'> <Link to='/cubasetnavbar' >Cubaset</Link></li>
                  <li onClick={handledrop} className='hover:border-b pb-2 border-white'> <Link to='/dronetechnology' >Drone Technology</Link></li>
                </ul>

              </li>
              <li>
              </li>
              <li onClick={handledrop} className='hover:border-b pb-2 border-white'> <Link to='/career' >Career</Link></li>
              <li onClick={handledrop} className='hover:border-b pb-2 border-white'> <Link to='/contact' >Contact</Link></li>
              <li onClick={handledrop} className='hover:border-b pb-2 border-white'> <Link to='/news' >News Room</Link></li>
              <li onClick={handledrop} className='hover:cursor-pointer py-3 px- rounded-lg text-center transition ease-in-out delay-150 bg-[#EA1C27] hover:-translate-y-1 hover:scale-110 hover:bg-indigo-500 duration-300'> <Link to='/launch' >Launch With US</Link></li>
            </ul>
          </div>
        </div>
      </div>
    </div>

  );
};

export default Navbar;