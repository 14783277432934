import React from 'react';
import astro from '../Images/Asteroids.png'

const Card = () => {
    return (
        <div className='bg-[#10141B] pb-40'>
            <p className='text-[#CFD6E3] ml-5 md:ml-10 pt-24 lg:ml-32 lg:pt-52'>Lakshya Sp<span className='text-[#EA1C27]'>A</span>ce </p>
            <div className='ml-5 lg:ml-80' >
                
                <p class='text-3xl mt-5 lg:mt-0 font-Montserrat text-white '>LAKSHYA Sp<span className='text-[#EA1C27]'>A</span>ce is a Aerospace Based Innovative<br/> Designing And Manufacture Company</p>
            </div>
            <div className='ml-5 lg:ml-24 flex flex-col lg:flex-row lg:justify-center'>

                <div class=" shadow-xl bg-[#380104] text-white mt-20 overflow-visible w-[280px] md:w-[323px] md:h-[389px]">
                    <div class="card-body ">
                        {/* <h1 class='text-7xl font-bold  -ml-8 -mt-20' style={{ "textIndent": "-20px", "whiteSpace": "nowrap", "overflow": "hidden" }}>0<span class='text-white'>1</span></h1> */}
                        <h2 class="card-title mt-10 font-Montserrat">Vision</h2>
                        <p>To inspire and serve the people around the globe through the power of apce thing, reflect the iconic idea, creative mind and innovative technologies that make us world promier company.</p>

                    </div>
                </div>


                <div class=" shadow-xl bg-[#600207] text-white mt-20  overflow-visible w-[280px] md:w-[323px] md:h-[389px]">
                    <div class="card-body">
                        {/* <h1 class='text-7xl font-bold -mt-20 -ml-8' style={{ "textIndent": "-20px", "whiteSpace": "nowrap", "overflow": "hidden" }}>0<span class='text-white'>2</span></h1> */}
                        <h2 class="card-title mt-10 font-Montserrat">Mission</h2>
                        <p>Build the best space 3D printed Products, cause no unnecessary harm, use business to inspire and implement solution to the space crisis</p>

                    </div>
                </div>


                <div>
                    <div class=" shadow-xl bg-[#95060E] text-white mt-20  overflow-visible w-[280px] md:w-[323px] md:h-[389px]">
                        <div class="card-body">
                            {/* <h1 class='text-7xl font-bold -mt-20 -ml-8' style={{ "textIndent": "-20px", "whiteSpace": "nowrap", "overflow": "hidden" }}>0<span class='text-white'>3</span></h1> */}
                            <h2 class="card-title mt-10 font-Montserrat">Aim</h2>
                            <p>Building a nationwide high end space technological ecosystem for a common man and industry.</p>

                        </div>
                    </div>
                </div>
            </div>
            <img src={astro} alt='' className='-mt-36 md:-mt-60 absolute w-[660px] h-[660px] '/>
           
        </div>
    );
};

export default Card;
