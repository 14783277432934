
import './App.css';
import About from './Pages/About/About';
import Home from './Pages/Home/Home';
import 'react-toastify/dist/ReactToastify.css';
import Navbar from './Pages/Shared/Navbar/Navbar';
import Career from './Pages/Career/Career'
import { Routes, Route } from "react-router-dom";
import Division from './Pages/Division/Division';
import Rocket from './Pages/Division/Rocket/Rocket';
import CubasetNavbar from './Pages/Division/CubasetNavbar/CubasetNavbar';
import DroneTechnology from './Pages/Division/DroneTechnology/DroneTechnology';
import Contact from './Pages/Contact/Contact';
import LaunchWithUs from './Pages/LaunchWithUS/LaunchWithUs';
import Footer from './Pages/Shared/Footer/Footer';
import { useState } from 'react';
import { useRef } from 'react';
import { useEffect } from 'react';
import News from './Pages/News/News';


function App() {
  const [showDD, setShowDD] = useState(true);
  const refOne = useRef();
  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);
  const handleClickOutside = (e) => {
    if (!refOne.current.contains(e.target)) {
      setShowDD(false);

    }
  };
  return (
    <div>
      <div ref={refOne}>
        <Navbar showDD={showDD} setShowDD={setShowDD} />
      </div>
      <div className='mt-10'>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='about' element={<About></About>} />
          <Route path='division' element={<Division></Division>} />
          <Route path='rocket' element={<Rocket></Rocket>} />
          <Route path='cubasetnavbar' element={<CubasetNavbar></CubasetNavbar>} />
          <Route path='dronetechnology' element={<DroneTechnology></DroneTechnology>} />
          <Route path='contact' element={<Contact></Contact>} />
          <Route path='launch' element={<LaunchWithUs></LaunchWithUs>} />
          <Route path='career' element={<Career></Career>} />
          <Route path='news' element={<News></News>} />
        </Routes>
      </div>

      <Footer />



    </div>
  );
}

export default App;
