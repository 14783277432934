import React from 'react';
import img1 from './images/Ellipse24.jpeg'
import img2 from './images/Ellipse25.jpg'
import img from './images/Image.png'
import divider from './images/divider.png'
import dividerf from './images/dividerflipped.png'
const Upcoming = () => {
    return (
       <div className=''>
         <div>
            <img src={divider} alt='' className='absolute w-2 mt-5 h-72 md:h-max ml-[80%] lg:ml-[48.5%]' />
            <div class='grid grid-cols-1 lg:grid-cols-2 gap-4 justify-items-center'>
               <div class='md:ml-auto' >
                  <img src={img1} className='-z-50 rounded-full w-[230px] h-[230px] md:w-[400px] md:h-[400px]  mt-32'/>
                  <h1 class='text-4xl md:text-7xl  text-white -mt-16 md:-mt-28 -ml-1 lg:-ml-24'>Upcoming <br/>Missions</h1>
               </div>
               <div className='hidden lg:block'>

               </div>
               <div className='hidden lg:block'>

               </div>
               
               <div class='mt-10 lg:mt-0'>
               <img src={dividerf} alt='' className='absolute h-72 md:h-max w-2 mt-20 lg:mt-36 ml-5 lg:ml-0'/>
               <img src={img2} className='-z-50 rounded-full w-[250px] h-[250px] md:w-[400px] md:h-[400px]  '/>
                  <h1 class='text-4xl  md:text-7xl text-white font-abeezee -mt-[100%] ml-20 lg:ml-60'>4 Patents<br/>Pending</h1>
               </div>

            </div>
        </div>
        <div class="bg-[url('/src/Pages/Home/Upcoming/images/bg.png')] bg-no-repeat bg-cover mt-96">
         <div class='grid grid-cols-1'>
         <p className='text-sm font-bold md:text-5xl text-white font-sans md:mt-64 ml-5  md:ml-10 lg:ml-32'>Our Products</p>
         <h1 class='text-base md:text-4xl font-serif mt-5  text-white ml-5 lg:ml-32'>
           
            Diversity in Innovation, Technology and  services at Lakshaya space has Rockets,  Satellites and Drone Technology
            </h1>
            <div className='flex flex-col justify-center px-5 lg:px-10'>
            <iframe className='w-full lg:h-[30rem] pt-10' src="https://www.youtube.com/embed/YQukA9MdoyE" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            <iframe className='w-full lg:h-[30rem] pt-10'  src="https://www.youtube.com/embed/PeZ3_fnbaVM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            <iframe  className='w-full lg:h-[30rem] mt-10'  src="https://www.youtube.com/embed/EP5Fu1JH2OY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
            
         </div>

        </div>
       </div>
        
    );
};

export default Upcoming;