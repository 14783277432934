import React from 'react';
import value from '../Images/value.png'

import oval from '../Images/Oval.png'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoffee, faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { tab } from '@testing-library/user-event/dist/tab';
import { icon } from '@fortawesome/fontawesome-svg-core';
const JoinUs = () => {
        return (
                <div class="bg-gradient-to-b from-black to-slate-700">
                        <div class="bg-[url('/src/Pages/Career/Images/careerbg1.png')] bg-no-repeat bg-cover">
                                <img src={oval} alt=' ' className='absolute hidden lg:block' />
                                <div className='pt-60 pl-5 lg:pl-80' >
                                        <h1 className='text-5xl text-white font-Opensans'>Come To Join Us!</h1>
                                        <p className='text-white mt-5'>From year to year we strive to invent the most<br /> innovative technology that is used by both small<br></br> enterprises and space enterprises.</p>
                                        <button class="btn bg-[#1565D8]  mt-5 ">See Current Opening <FontAwesomeIcon className='ml-2' icon={faArrowRight} /></button>

                                </div>
                                <div className='mt-[30%] mb-[5%] ml-10  mr:ml-20'>
                                        <h1 className='text-4xl  text-white font-Opensans '>A Few Things You Should<br /> Know about us</h1>
                                </div>
                                <div className='flex flex-col lg:flex-row justify-between pb-[15%]'>
                                        <div className='text-white mt-28 lg:w-1/2 ml-20'>
                                                <h1 className='text-3xl font-Opensans mb-7'>Our Values</h1>
                                                <p className='text-lg'>Aerospace application,innovation<br/> and technologies from<br/> industralists to common man</p>
                                        </div>
                                        <div>
                                      <img src={value} className='w-[400px] h-[350px] md:w-[565px] md:h-[490px]'/>
                                        </div>

                                </div>



                        </div>
                </div>
        );
};

export default JoinUs;