import React from 'react';
import Banner from './Banner/Banner';
import Banner2 from './Banner/Banner2';
import Banner3 from './Banner/Banner3';
import Collboration from './Collaboration/Collboration';
import Upcoming from './Upcoming/Upcoming';



const Home = () => {
    return (
        <div className='bg-black '>
            <Banner/>
            <Banner2/>
            <Banner3/>
            <Upcoming/>
            <Collboration></Collboration>
            

           
        </div>
    );
};

export default Home;