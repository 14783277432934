import React, { useState } from 'react';
import Cubasets from './Cubasets';
import Rockets from './Rockets';
import Drones from './Drones'

const LaunchWithUs = () => {
    const [rockets, setRockets] = useState(true)
    const [cubasets, setCubasets] = useState(false)
    const [drones, setDrones] = useState(false)
    const handlerocket = () => {
        setRockets(true)
        setCubasets(false)
        setDrones(false)
    }
    const handlecubaset = () => {
        setRockets(false)
        setCubasets(true)
        setDrones(false)
    }
    const handledrone = () => {
        setRockets(false)
        setCubasets(false)
        setDrones(true)
    }

    return (
        <div className='bg-black text-white py-20 w-full'>
            <div class="btn-group justify-center mb-20 ">
                <button onClick={handlerocket} class={`btn bg-[#95060E]`}>Rockets</button>
                <button onClick={handlecubaset} class="btn bg-white text-black">Cubesat</button>
                <button onClick={handledrone} class="btn bg-white text-black">Drone</button>
            </div>
            <div>
                {rockets && <Rockets></Rockets>}
                {drones && <Drones></Drones>}
                {cubasets && <Cubasets></Cubasets>}
                
            </div>




        </div>
    );
};

export default LaunchWithUs;