import React from 'react';
import ContactFrom from './ContactFrom';
import HeroContact from './HeroContact';
import OurContact from './OurContact';

const Contact = () => {
    return (
        <div className='bg-black'>
           <HeroContact></HeroContact>
           <ContactFrom></ContactFrom>
           
        </div>
    );
};

export default Contact;