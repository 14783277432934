import React from 'react';
import Typewriter from 'typewriter-effect';
import bgimg from './images/bg.jpg'

const Banner = () => {
  return (
    <div class='bg-black'>
      <div className="bg-[url('/src/Pages/Home/Banner/images/bg.jpg')] bg-no-repeat bg-cover">
        
        <div class=" 2xl:mx-auto text-white ">
          <div class="pt-10 lg:pt-60  mb-48 md:mb-0 md:pb-20">
            <h1 class=" mt-8 text-5xl lg:ml-32 lg:text-8xl font-sans font-extralight  text-center">Opening Space<br/> For ALL<Typewriter options={{ strings: [' .....', ], autoStart: true, loop: true, }} /></h1>
           
            <p class=" mt-5 md:mt-36 text-xs md:text-4xl font-Questrial ml-3 lg:ml-10 md:mr-16  ">Lakshya space is aiming in providing efficient technology in upcoming space program held. With keen passion our research and innovation team is aiming in building better rocket engines and technology. We would wholeheartedly thank all the people who are a part of this ambitious mission...</p>
          </div>
        </div>
      </div>

    </div>
  );
};

export default Banner;