import React from 'react';

const Imgmodal = ({img1,onclose}) => {
    return (
    <div>
<input type="checkbox" id="my-modal-3" className="modal-toggle" />
<div className="modal">
  <div className=" relative">
    <label htmlFor="my-modal-3" className="btn btn-sm btn-circle absolute right-2 top-2" onclick={onclose}>✕</label>
    <img className='w-[900px] h-[500px]' src={img1}/>
  </div>
</div></div>
    );
};

export default Imgmodal;