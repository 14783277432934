import React from 'react';
import JoinUs from './JoinUS/JoinUs';
import WhoWR from './JoinUS/WhoWR';
import WWdo from './JoinUS/WWdo';


const Career = () => {
    return (
        <div>
            <JoinUs/>
            {/* Who we are */}
            <WhoWR/>
            {/* What we do */}
            <WWdo/>
            {/* Why work here */}
            
        </div>
    );
};

export default Career;