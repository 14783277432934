import React from 'react';
import ContactDrone from './ContactDrone';
import FeatureDrone from './FeatureDrone';
import FooterDrone from './FooterDrone';
import TeamDrone from './TeamDrone';

const DroneTechnology = () => {
    return (
        <div className='bg-black'>
            <FeatureDrone></FeatureDrone>
            <TeamDrone></TeamDrone>
            <ContactDrone/>
            
        </div>
    );
};

export default DroneTechnology;