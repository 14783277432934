import React, { useRef, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import emailjs from '@emailjs/browser';
const Drones = () => {
    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_x8q2r0a', 'template_ptmkk8h', form.current, 'uwgWYqC_idd4e-MHv')
            .then((result) => {
                console.log(result.text);
                toast.success("Email Successfully sent")
                form.current.reset()
            }, (error) => {
                toast.error(error.text);
            });
    };
    return (
        <div className='pb-60'>
            <form ref={form} onSubmit={sendEmail}>
                <div class='lg:flex lg:justify-evenly lg:mt-10  lg:ml-[156px]'>
                    <div className='space-y-11 w-full'>
                        <input type="text" required name="user_name" placeholder="Name" class="input w-full max-w-md bg-[#262837]" /><br />
                        <input type="text" required name="email" placeholder="Email" class="input w-full max-w-md bg-[#262837]" /><br />
                        <input type="text" required name="address" placeholder="Adddress to Company" class="input w-full max-w-md bg-[#262837] py-10" /><br />

                        <select name="location" class="select w-full max-w-md bg-[#262837]">
                            <option disabled selected>Launch Location</option>
                            <option>India</option>

                        </select><br />

                        <select name="playload" class="select w-full max-w-md bg-[#262837]">
                            <option disabled selected>Playload Mass</option>
                            <option>5 kg</option>
                            <option>10-12 kg</option>
                            <option>10-15 kg</option>

                        </select><br />


                        <select name="site" class="select w-full max-w-md bg-[#262837]">
                            <option disabled selected>Preferred site of Payload Integration</option>
                            <option>Launchpad</option>

                        </select><br />




                    </div>
                    <div className='w-full mt-10'>
                        <input type="text" required name="company" placeholder="Company name" class="input w-full max-w-md bg-[#262837]" /><br />
                        <input type="text" required name="contact" placeholder="Contact No" class="input w-full max-w-md bg-[#262837] mt-10" /><br />
                        <input type="text" required name="purpose" placeholder="Purpose of contact" class="input w-full max-w-md bg-[#262837] mt-10" /><br />

                        <select name="Orbital_Inclination" class="select w-full max-w-md bg-[#262837] mt-10">
                            <option disabled selected>orbital Inclination</option>
                            <option>SSO</option>
                            <option>50 deg to SSO</option>
                            <option>0 - 50 deg</option>

                        </select><br />

                        <select name="Orbital_Altitude" class="select w-full max-w-md bg-[#262837] mt-10">
                            <option disabled selected>Orbital Altitude</option>
                            <option>10 -12 km</option>
                            <option>12 - 20 km</option>
                            <option>20 - 25 km</option>

                        </select><br />
                    </div>
                    <div>
                    </div>
                </div>
                <div className='flex justify-center'>
                    <select name='drone' class="select w-full max-w-md bg-[#262837] mt-40">
                        <option disabled selected>Types of Drone</option>
                        <option>Fixed Wing</option>
                        <option>MultiCopter</option>

                    </select><br />

                </div>
                <div className='flex justify-center'>
                    <button class="btn btn-sm text-white mt-52 bg-black border border-[#95060E] w-40 ">Submit</button>
                </div>
            </form>
            <ToastContainer></ToastContainer>

        </div>
    );
};

export default Drones;