import React from 'react';
import img from '../Images/Rocket.jpg'

const Features = () => {
    return (
        <div className='bg-white pb-32 px-5 lg:px-0'>
                <div className='grid grid-cols-1 md:grid-cols-2 md:mx-10 mx-3 gap-10 '>
                    <div>
                        <img src={img} className='h-[300px] md:h-[500px] w-full mt-10' alt="" />
                    </div>
                    <div>
                        <p className='font-abeezee text-sm md:text-2xl mt-3 md:mt-20'>A sounding rocket, also named as research rocket is specially designed rocket for carrying instruments to perform scientific experiments and certain measurements at its sub-orbital flight. It can also be given name as low altitude rocket as it travels to a shorter altitude compared other space rockets, and that is the reason it is being used for preferably for measuring atmospheric conditions and experiments like agricultural survey and cloud seeding.ospheric conditions and experiments like agricultural survey. and cloud seeding.</p>
                    </div>
                </div>
            <div className='flex flex-col lg:flex-row justify-between mt-32'>
                <div className='ml-5 md:ml-24' >
                    <h1 className='font-serif font-bold text-xl md:text-3xl'>
                        Project Green:
                    </h1>
                    <h1 className='text-[#EA1C27] text-lg md:text-2xl mt-10 md:mt-20 font-Poppins'>
                    A technology that is aiming<br></br> to combine rocket science<br></br> and bio fuels attogether in <br/>a same technological<br></br> platform.
                    </h1>
                    </div>
                    <div className='grid grid-cols-2 gap-10 md:gap-20 lg:mr-32 mt-10 lg:mt-0'>
                        <div>
                            <h1 className="text-lg md:text-2xl font-Poppins">
                            Eco- friendly:
                                <p className='text-xs'>
                                ecofiendly with less<br/> carbon emission
                                </p>
                            </h1>
                        </div>

                        <div>
                        <h1 className='text-lg md:text-2xl font-Poppins'>
                        Low- cost production:
                                <p className='text-xs'>
                                Four times lesser in<br/> manufacturing and production<br/> compared to normal conventional<br/> rocket engine.
                                </p>
                            </h1>
                        </div>

                        <div>
                        <h1 className='text-lg md:text-2xl font-Poppins'>
                        Specific Impulse:
                                <p className='text-xs'>
                                comparetaily high specific<br/> impluse than other<br/> fiendly rocket engine
                                </p>
                            </h1>
                        </div>

                        <div>
                        <h1 className='text-lg md:text-2xl font-Poppins'>
                        Reusability:
                                <p className='text-xs'>
                                Can be used for two or<br/> more than two launches.
                                </p>
                            </h1>
                        </div>
                    </div>
                
            </div>

            <div className='flex flex-col lg:flex-row justify-between mt-32'>
                <div className='ml-5 md:ml-24' >
                  
                    <h1 className='text-[#EA1C27] text-lg md:text-2xl mt-10 md:mt-20 font-Poppins'>
                    A technology that is a<br/> mixture of electro-chemistry<br/> and rocket science.
                    </h1>
                    </div>
                    <div className='grid grid-cols-2 gap-10 md:gap-20 lg:mr-32 mt-10 lg:mt-0'>
                        <div>
                            <h1 className="text-xl md:text-2xl font-Poppins">
                            Eco- friendly:
                                <p className='text-xs'>
                                ecofiendly with less <br/> carbon emission
                                </p>
                            </h1>
                        </div>

                        <div>
                        <h1 className='text-2xl font-Poppins'>
                        Low- cost production:
                                <p className='text-xs'>
                                Four times lesser in<br/> manufacturing and production<br/> compared to normal conventional<br/> rocket engine.
                                </p>
                            </h1>
                        </div>

                        <div>
                        <h1 className='text-2xl font-Poppins'>
                        Prolusion Unit: 
                                <p className='text-xs'>
                                Elctro-chemical based <br/> "water propulsion".
                                </p>
                            </h1>
                        </div>

                        <div>
                        <h1 className='text-2xl font-Poppins'>
                        Control system: 
                                <p className='text-xs'>
                                A reliable cobtrol law(CLAW)/Propulsion <br/> congtrol unit working behind <br/>the propulsion RCS.
                                </p>
                            </h1>
                        </div>
                    </div>
                
            </div>
        </div>
    );
};

export default Features;