import React from 'react';

const Divider = () => {
    return (
        <div className='text-white'>
            <div class='text-white  bg-[#10141B] pt-20 pb-20 md:pb-96 px-5 lg:px-0'>

                <h1 class='md:ml-36 text-2xl font-Montserrat font-medium'>Our Stories</h1>
                <div class='mt-20'>

                    <h1 class='text-2xl font-Montserrat lg:ml-80 mt-5'>If you are going to have a Story, have a Big Story<br /> or None at all - Joseph Camphbell. </h1>
                    <div class=' mt-20 lg:ml-80'>
                        <div >
                            <h1 className='text-base text-[#CFD6E3]'>So here is a Glimpse of how our Journey started...</h1>
                        </div>

                    </div>

                </div>
                <div className='mt-20 flex justify-center md:-ml-5'>
                    <div className="flex flex-col lg:flex-row">
                        <div className='grid grid-cols-1 w-full md:w-[400px]'>
                            <div className="grid h-40 card bg-[#688CE8]  pt-5 px-5">2022<br /><br />Incorporation at Team Lakshya Space LLP - 2022 August. </div>
                            <div className="grid h-40 card bg-[#4A6EC7]   pt-5 px-5 mt-10">2021<br></br><br />Open our First incubation centre at vemana institute of technology july 2021.</div>
                            <div className="grid h-40 card bg-[#4A6EC7]   pt-5 px-5 mt-10">2020<br /><br />Open our First incubation centre at vemana institute of technology july 2021.</div>
                        </div>
                        <div className="divider lg:divider-horizontal "></div>
                        <div className='grid grid-cols-1 w-full md:w-[400px]'>
                            <div className="grid  h-40 card bg-[#688CE8]  pt-5 px-5 mt-24"> 2022<br /><br />Open our First incubation centre at vemana institute of technology july 2021.</div>
                            <div className="grid  h-40 card bg-[#4A6EC7]  pt-5 px-5 mt-10">2021<br /><br />Formation of core and scientific team - 2021 Jan</div>
                            <div className="grid  h-40 card bg-[#4A6EC7]  pt-5 px-5 mt-10">2020<br /><br />We are Established in July 2020.</div>

                        </div>
                    </div>
                </div>
            </div>





        </div>
    );
};

export default Divider;