import React from 'react';
import logo from '../Navbar/logo 1.png'
import { Link } from 'react-router-dom';
import {BiPhoneCall} from 'react-icons/bi'
import {FiMail} from 'react-icons/fi'

const Footer = () => {
    return (
<footer className="footer p-10 bg-black text-white">
  <div>
    <p className='text-3xl font-bold'>Lakshya Sp<span className='text-[#EA1C27]'>A</span>ce</p>
    <p>Establishment of Lakshya Space - 2020 July</p>
  </div> 
  <div>
    <span className="footer-title">Services</span> 
    <li  className='hover:border-b pb-2 border-white'> <Link to='/about' >About</Link></li>
    <li  className='hover:border-b pb-2 border-white'> <Link to='/rocket'  >Rocket</Link></li>
    <li  className='hover:border-b pb-2 border-white'> <Link to='/cubasetnavbar' >Cubaset</Link></li>
    <li  className='hover:border-b pb-2 border-white'> <Link to='/dronetechnology' >Drone Technology</Link></li>
  </div> 
  <div>
    <span className="footer-title">Contact Us</span> 
    <li  className='hover:border-b pb-2 border-white'> <Link to='/career' >Career</Link></li> 
    <li  className='hover:border-b pb-2 border-white'> <Link to='/contact' >Contact</Link></li>
   
  </div> 
  <div>
   
   <div className='flex space-x-2'>
   <p className='mt-6 text-base'><BiPhoneCall/></p>
   <p className='mt-5 hover:border-b pb-2 border-white'>+91-7019561885</p>
   </div>
  <div className='flex space-x-2'>
  <p className='mt-6 text-base'><BiPhoneCall/></p>
  <p className='mt-5 hover:border-b pb-2 border-white'>+91- 9731559391</p>
  </div>
   

  
    <div className='flex space-x-2'>
    <p className='mt-6 text-base'><FiMail/></p>
    <p className='mt-5 hover:border-b pb-2 border-white'>info@lakshyaspace.in </p>
    </div>



        
  </div>
</footer>
    );
};

export default Footer;