import React from 'react';
import cuba from './Images/cubaset.jpg'
import { AiOutlineRight } from 'react-icons/ai'
import news1 from './Images/news-1.png'
import news2 from './Images/news-2.png'
import news3 from './Images/news-3.png'
import news4 from './Images/news-4.png'
import news5 from './Images/news-5.png'
import news6 from './Images/news-6.png'
import news7 from './Images/news-7.png'
import news8 from './Images/news-8.png'
import news9 from './Images/news-9.png'
import news10 from './Images/news-10.png'
import news11 from './Images/news-11.png'
import news12 from './Images/news-12.png'

const News = () => {
    return (
        <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-10 mx-5 mt-20 pb-10'>
            <div className="card  bg-base-100 shadow-2xl rounded-3xl">
                <figure><img src={news1} className="h-[200px] w-full" alt="Shoes" /></figure>
                <div className="card-body">
                    <h2 className="font-mono  text-lg">
                    Business Standard
                        
                    </h2>
                    <p className='font-Montserrat font-bold'>Garuda Aerospace to deliver Swiggy grocery parcels in Bengaluru via drones</p>
             <div className='flex'>
             <a href="https://www.business-standard.com/article/companies/pm-wants-drones-to-deliver-postal-packages-to-remote-areas-garuda-aerospace-121102400303_1.html" target="_blank" rel="noreferrer">
          <button className='text-sky-600 font-mono'>know more </button>
        </a>
        <p className='mt-[6px] text-sky-600 '><AiOutlineRight/></p>
             </div>
                </div>
            </div>


            <div className="card  bg-base-100 shadow-2xl rounded-3xl">
                <figure><img src={news2} className="h-[200px] w-full" alt="Shoes" /></figure>
                <div className="card-body">
                    <h2 className="font-mono  text-lg">
                    The States Man
                        
                    </h2>
                    <p className='font-Montserrat font-bold'>Now get your Swiggy order by air through drones</p>
                    <div className='flex'>
             <a href="https://www.aninews.in/news/national/general-news/garuda-aerospace-ceo-meets-pm-says-indian-drone-industry-set-to-skyrocket-under-modis-leadership20211026232619/">
          <button className='text-sky-600 font-mono'>know more </button>
        </a>
        <p className='mt-[6px] text-sky-600 '><AiOutlineRight/></p>
             </div>
                </div>
            </div>


            <div className="card  bg-base-100 shadow-2xl rounded-3xl">
                <figure><img src={news3} className="h-[200px] w-full" alt="Shoes" /></figure>
                <div className="card-body">
                    <h2 className="font-mono  text-lg">
                    BGR
                        
                    </h2>
                    <p className='font-Montserrat font-bold'>Uber launches robot-assisted food delivery service in two US cities</p>
                    <div className='flex'>
             <a href="https://www.thehindubusinessline.com/companies/elon-musks-like-a-1-m-boon-for-garuda-aerospace/article35467628.ece" target="_blank" rel="noreferrer">
          <button className='text-sky-600 font-mono'>know more </button>
        </a>
        <p className='mt-[6px] text-sky-600 '><AiOutlineRight/></p>
             </div>
                </div>
            </div>



            <div className="card  bg-base-100 shadow-2xl rounded-3xl">
                <figure><img src={news4} className="h-[200px] w-full" alt="Shoes" /></figure>
                <div className="card-body">
                    <h2 className="font-mono  text-lg">
                    The Hindu
                        
                    </h2>
                    <p className='font-Montserrat font-bold'>Swiggy to use drones to replenish grocery stocks in pilot project</p>
                    <div className='flex'>
             <a href="https://www.business-standard.com/article/companies/garuda-aerospace-to-export-12-000-drones-lowest-bidder-in-rites-122050500588_1.html" target="_blank" rel="noreferrer">
          <button className='text-sky-600 font-mono'>know more </button>
        </a>
        <p className='mt-[6px] text-sky-600 '><AiOutlineRight/></p>
             </div>
                </div>
            </div>



            <div className="card  bg-base-100 shadow-2xl rounded-3xl">
                <figure><img src={news5} className="h-[200px] w-full" alt="Shoes" /></figure>
                <div className="card-body">
                    <h2 className="font-mono  text-lg">
                    Reuters
                        
                    </h2>
                    <p className='font-Montserrat font-bold'>Indonesia airline Garuda proposes another debt restructuring extension</p>
                    <div className='flex'>
             <a href="https://timesofindia.indiatimes.com/business/india-business/swiggy-ties-up-with-garuda-aerospace-for-drone-delivery-trials-in-ncr-bglr/articleshow/91281594.cms" target="_blank" rel="noreferrer">
          <button className='text-sky-600 font-mono'>know more </button>
        </a>
        <p className='mt-[6px] text-sky-600 '><AiOutlineRight/></p>
             </div>
                </div>
            </div>



            <div className="card  bg-base-100 shadow-2xl rounded-3xl">
                <figure><img src={news6} className="h-[200px] w-full" alt="Shoes" /></figure>
                <div className="card-body">
                    <h2 className="font-mono  text-lg">
                    Bangalore Mirror
                        
                    </h2>
                    <p className='font-Montserrat font-bold'>The Sunday Read: Will groceries fall from the sky?</p>
                    <div className='flex'>
             <a href="https://www.livemint.com/companies/news/swiggy-ties-up-with-garuda-aerospace-to-deliver-groceries-using-drones-in-delhi-ncr-bengaluru-11651645666330.html" target="_blank" rel="noreferrer">
          <button className='text-sky-600 font-mono'>know more </button>
        </a>
        <p className='mt-[6px] text-sky-600 '><AiOutlineRight/></p>
             </div>
                </div>
            </div>



            <div className="card  bg-base-100 shadow-2xl rounded-3xl">
                <figure><img src={news7} className="h-[200px] w-full" alt="Shoes" /></figure>
                <div className="card-body">
                    <h2 className="font-mono  text-lg">
                    CNBC TV18
                        
                    </h2>
                    <p className='font-Montserrat font-bold'>How this Chennai startup is helping Swiggy deliver packages through drones</p>
                    <div className='flex'>
             <a href="https://yourstory.com/2022/05/indian-startup-funding-roundup-news-garuda-nutty-yogi-immersive-vision-60plus-india-freed" target="_blank" rel="noreferrer">
          <button className='text-sky-600 font-mono'>know more </button>
        </a>
        <p className='mt-[6px] text-sky-600 '><AiOutlineRight/></p>
             </div>
                </div>
            </div>


            <div className="card  bg-base-100 shadow-2xl rounded-3xl">
                <figure><img src={news8} className="h-[200px] w-full" alt="Shoes" /></figure>
                <div className="card-body">
                    <h2 className="font-mono  text-lg">
                    Times Now News
                        
                    </h2>
                    <p className='font-Montserrat font-bold'>Swiggy to pilot drone-based grocery delivery</p>
                    <div className='flex'>
             <a href="https://www.business-standard.com/article/companies/pm-wants-drones-to-deliver-postal-packages-to-remote-areas-garuda-aerospace-121102400303_1.html" target="_blank" rel="noreferrer">
          <button className='text-sky-600 font-mono'>know more </button>
        </a>
        <p className='mt-[6px] text-sky-600 '><AiOutlineRight/></p>
             </div>
                </div>
            </div>


            <div className="card  bg-base-100 shadow-2xl rounded-3xl">
                <figure><img src={news9} className="h-[200px] w-full" alt="Shoes" /></figure>
                <div className="card-body">
                    <h2 className="font-mono  text-lg">
                       The Hindu
                        
                    </h2>
                    <p className='font-Montserrat font-bold'>Drone start-up Garuda Aerospace eyes to raise over $30 million</p>
                    <div className='flex'>
             <a href="https://inc42.com/buzz/drone-startup-garuda-gets-global-funding-bags-over-8000-orders-from-malaysia-panama-uae/" target="_blank" rel="noreferrer">
          <button className='text-sky-600 font-mono'>know more </button>
        </a>
        <p className='mt-[6px] text-sky-600 '><AiOutlineRight/></p>
             </div>
                </div>
            </div>


            <div className="card  bg-base-100 shadow-2xl rounded-3xl">
                <figure><img src={news10} className="h-[200px] w-full" alt="Shoes" /></figure>
                <div className="card-body">
                    <h2 className="font-mono  text-lg">
                    The Hindu Business Line
                        
                    </h2>
                    <p className='font-Montserrat font-bold'>Chennai-based Garuda Aerospace aims to manufacture six lakh drones by 2025</p>
                    <div className='flex'>
             <a href="https://www.freepressjournal.in/business/garuda-aerospace-to-deliver-swiggy-grocery-parcels-in-bengaluru-via-drones" target="_blank" rel="noreferrer">
          <button className='text-sky-600 font-mono'>know more </button>
        </a>
        <p className='mt-[6px] text-sky-600 '><AiOutlineRight/></p>
             </div>
                </div>
            </div>


            <div className="card  bg-base-100 shadow-2xl rounded-3xl">
                <figure><img src={news11} className="h-[200px] w-full" alt="Shoes" /></figure>
                <div className="card-body">
                    <h2 className="font-mono  text-lg">
                    Money Control
                        
                    </h2>
                    <p className='font-Montserrat font-bold'>Five states plan to roll out drones for fertiliser usage in next 2-3 months: Sources</p>
                    <div className='flex'>
             <a href="https://www.thestatesman.com/technology/now-get-swiggy-order-air-drones-1503066533.html" target="_blank" rel="noreferrer">
          <button className='text-sky-600 font-mono'>know more </button>
        </a>
        <p className='mt-[6px] text-sky-600 '><AiOutlineRight/></p>
             </div>
                </div>
            </div>


            <div className="card  bg-base-100 shadow-2xl rounded-3xl">
                <figure><img src={news12} className="h-[200px] w-full" alt="Shoes" /></figure>
                <div className="card-body">
                    <h2 className="font-mono  text-lg">
                    IHS Markit
                        
                    </h2>
                    <p className='font-Montserrat font-bold'>India allows drone-based pesticide spraying</p>
                    <div className='flex'>
             <a href="https://www.bgr.in/apps/uber-launches-robot-assisted-food-delivery-service-in-two-us-cities-1271757/" target="_blank" rel="noreferrer">
          <button className='text-sky-600 font-mono'>know more </button>
        </a>
        <p className='mt-[6px] text-sky-600 '><AiOutlineRight/></p>
             </div>
                </div>
            </div>
           






            


        </div>
    );
};

export default News;