import React from 'react';

const HeroContact = () => {
    return (
        <div className='bg-black'>
            <div className="bg-[url('/src/Pages/Home/Banner/images/bg.jpg')] bg-no-repeat bg-cover">
                <h1 className='text-center text-2xl font-Roboto text-white pt-36 pb-20'>
                    We believe that the work should be based on friendly<br /> relations and positive emotions, so continue to<br /> communicate with our customers and after the delivery of<br /> the project.
                </h1>
            </div>
            <div>
           

            </div>

        </div>
    );
};

export default HeroContact;