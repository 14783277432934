import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Pagination } from "swiper";
import img1 from './Images/Img1.png'
import img2 from './Images/img2.png'
import img3 from './Images/img3.png'
import img4 from './Images/img4.png'
import img5 from './Images/img5.png'
import img6 from './Images/img6.png'
import img7 from './Images/img7.png'
import img8 from './Images/img8.png'
import img9 from './Images/img9.png'
import img10 from './Images/img10.png'

const Collboration = () => {
  return (
    <div className='mb-280'>
      <div>
        <h1 class='text-xl font-medium lg:text-5xl font-inter text-center text-white py-10'>Our collaboration with<br /> leading brands &<br /> agencies</h1>
      </div>
      <div className="">
        <Swiper
           breakpoints={{
            // when window width is >= 640px
            640: {
              width: 640,
              slidesPerView: 1,
            },
            // when window width is >= 768px
            1020: {
              width: 1020,
              slidesPerView: 5,
            },
          }}
          slidesPerView={2}
          spaceBetween={5}
          pagination={{
            clickable: true,
          }}
          modules={[Pagination]}
          className="mySwiper"
        >
          <SwiperSlide><img className="w-[150px] h-[75px] md:w-[200px] md:h-[200px]" src={img1} /></SwiperSlide>
          <SwiperSlide><img className="w-[150px] h-[75px] md:w-[200px] md:h-[200px]" src={img2} /></SwiperSlide>
          <SwiperSlide><img className="w-[150px] h-[75px] md:w-[200px] md:h-[200px]" src={img3} /></SwiperSlide>
          <SwiperSlide><img className="w-[150px] h-[75px] md:w-[200px] md:h-[200px]" src={img4} /></SwiperSlide>
          <SwiperSlide><img className="w-[150px] h-[75px] md:w-[200px] md:h-[200px]" src={img5} /></SwiperSlide>
          <SwiperSlide><img className="w-[150px] h-[75px] md:w-[200px] md:h-[200px]" src={img6} /></SwiperSlide>
          <SwiperSlide><img className="w-[150px] h-[75px] md:w-[200px] md:h-[200px]" src={img7} /></SwiperSlide>
          <SwiperSlide><img className="w-[150px] h-[75px] md:w-[200px] md:h-[200px]" src={img8} /></SwiperSlide>
          <SwiperSlide><img className="w-[150px] h-[75px] md:w-[200px] md:h-[200px]" src={img9} /></SwiperSlide>
          <SwiperSlide><img className="w-[150px] h-[75px] md:w-[200px] md:h-[200px]" src={img10} /></SwiperSlide>

        </Swiper>
      </div>
    </div>
  );
};

export default Collboration;