import React from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import { EffectCoverflow, Pagination } from "swiper";
import img from '../Images/Mukul Manohar.jpeg'
import img1 from '../Images/Dhanush.jpeg'
import img2 from '../Images/Deepak.jpeg'
import img3 from '../Images/Anjuneya.jpeg'
import img4 from '../Images/Satish.jpg'
import img5 from '../Images/PunitMK.jpeg'
import img6 from '../Images/Soham D.jpeg'
const TeamCuba = () => {
    return (
        <div className='mt-5 bg-white pb-20'>
            <div className='text-center pt-20'>
                <h1 className='text-4xl font-Roboto font-bold'>Our Team</h1>
            </div>
            <div className='mt-5'>
                <>
                    <Swiper
                        effect={"coverflow"}
                        grabCursor={true}
                        centeredSlides={true}
                        slidesPerView={"3"}
                        coverflowEffect={{
                            rotate: 50,
                            stretch: 0,
                            depth: 100,
                            modifier: 1,
                            slideShadows: true,
                        }}
                        pagination={true}
                        modules={[EffectCoverflow, Pagination]}
                        className="mySwiper"
                    >
                        <SwiperSlide>
                            <div className="card w-60 md:w-96 bg-base-100 shadow-2xl">
                                <figure className="px-10 pt-10">
                                    <img src={img} alt="mukes" className="rounded-3xl w-[150px] md:w-[200px] shadow-2xl" />
                                </figure>
                                <div className="card-body items-center text-center">
                                    <h2 className="card-title">Mukul Manohar</h2>
                                    <p className='md:text-lg text-sm'>Director</p>
                                    <div className="card-actions">
                                    <p className='md:text-base text-xs'> Your tomorrow may never come. Today is the pupil of yesterday. Each day of your life, as soon as you open your eyes in the morning, you can square away for a happy and successful day.</p>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="card w-60 md:w-96 bg-base-100 shadow-2xl">
                                <figure className="px-10 pt-10">
                                    <img src={img2} alt="Deepaak" className="rounded-3xl w-[150px] md:w-[200px] shadow-2xl" />
                                </figure>
                                <div className="card-body items-center text-center">
                                    <h2 className="card-title">Deepak M K</h2>
                                    <p className='md:text-lg text-sm'>Founder</p>
                                    <div className="card-actions">
                                    <p className='md:text-base text-xs'> Your tomorrow may never come. Today is the pupil of yesterday. Each day of your life, as soon as you open your eyes in the morning, you can square away for a happy and successful day.</p>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="card w-60 md:w-96 bg-base-100 shadow-2xl">
                                <figure className="px-10 pt-10">
                                    <img src={img1} alt="Dhanush" className="rounded-3xl w-[150px] md:w-[200px] shadow-2xl" />
                                </figure>
                                <div className="card-body items-center text-center">
                                    <h2 className="card-title">Dhanush D B</h2>
                                    <p className='md:text-lg text-sm'>CO-Founder</p>
                                    <div className="card-actions">
                                    <p className='md:text-base text-xs'> Your tomorrow may never come. Today is the pupil of yesterday. Each day of your life, as soon as you open your eyes in the morning, you can square away for a happy and successful day.</p>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                        <div className="card w-60 md:w-96 bg-base-100 shadow-2xl">
                                <figure className="px-10 pt-10">
                                    <img src={img4} alt="Satish" className="rounded-3xl w-[150px] md:w-[200px] shadow-2xl" />
                                </figure>
                                <div className="card-body items-center text-center">
                                    <h2 className="card-title">Satish</h2>
                                    <p className='md:text-lg text-sm'>Head Business Development</p>
                                    <div className="card-actions">
                                    <p className='md:text-base text-xs'> Your tomorrow may never come. Today is the pupil of yesterday. Each day of your life, as soon as you open your eyes in the morning, you can square away for a happy and successful day.</p>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="card w-60 md:w-96 bg-base-100 shadow-2xl ">
                                <figure className="px-10 pt-10">
                                    <img src={img3} alt="ANJANEYA" className="rounded-2xl  w-[150px] md:w-[200px] shadow-2xl" />
                                </figure>
                                <div className="card-body items-center text-center">
                                    <h2 className="card-title">Anjaneya</h2>
                                    <p className='md:text-lg text-sm'>Aero Space Engineer</p>
                                    <div className="card-actions">
                                    <p className='md:text-base text-xs'> Your tomorrow may never come. Today is the pupil of yesterday. Each day of your life, as soon as you open your eyes in the morning, you can square away for a happy and successful day.</p>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="card w-60 md:w-96 bg-base-100 shadow-2xl ">
                                <figure className="px-10 pt-10">
                                    <img src={img5} alt="Anan" className="rounded-2xl  w-[150px] md:w-[200px] shadow-2xl" />
                                </figure>
                                <div className="card-body items-center text-center">
                                    <h2 className="card-title">Punit M k</h2>
                                    <p className='md:text-lg text-sm'>Aero Space Engineer</p>
                                    <div className="card-actions">
                                    <p className='md:text-base text-xs'> Your tomorrow may never come. Today is the pupil of yesterday. Each day of your life, as soon as you open your eyes in the morning, you can square away for a happy and successful day.</p>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>

                        <SwiperSlide>
                            <div className="card w-60 md:w-96 bg-base-100 shadow-2xl ">
                                <figure className="px-10 pt-10">
                                    <img src={img6} alt="Anan" className="rounded-2xl  w-[150px] md:w-[200px] shadow-2xl" />
                                </figure>
                                <div className="card-body items-center text-center">
                                    <h2 className="card-title">Soham D</h2>
                                    <p className='md:text-lg text-sm'>Team Lead Electronics</p>
                                    <div className="card-actions">
                                    <p className='md:text-base text-xs'> Your tomorrow may never come. Today is the pupil of yesterday. Each day of your life, as soon as you open your eyes in the morning, you can square away for a happy and successful day.</p>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>

                    </Swiper>
                </>
            </div>

            <div className='text-3xl font-Poppins text-[#EA1C27] text-center mt-24 px-5 lg:px-0'>
                <h1>
                    We believe that the work should be based on friendly relations <br />and positive emotions, so continue to communicate with our<br /> customers and after the delivery of the project.
                </h1>
                <button class="btn bg-black mt-20 font-Poppins ">get on touch</button>
            </div>
        </div>
    );
};

export default TeamCuba;