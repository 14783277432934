import React, { useState } from 'react';
import rocket from '../Images/Rocket.png'
import rac1 from '../Images/collab letter.jpg'
import rac2 from '../Images/Rectangle14.png'
import rac3 from '../Images/meet.jpg'
import Imgmodal from './Imgmodal';

const Rocket = () => {
    const [imgmod,setImgmod]=useState(null)
    const onclose=()=>{
        setImgmod(null)
    }
    return (
        <div class='text-white  md:-mt-[35%] lg:ml-20' >
          
             <img src={rocket} className='z-[1]'/>
           
            <div className=' p-5 lg:p-0'>
            
                <div class=' z-[2] mt-[-60%] lg:ml-60'>
                <p className='text-xl md:text-3xl -ml-60 mt-[-90%]'>Our Gallery</p>
              
                
                </div>
                <div class='grid grid-cols-1 md:grid-cols-2 gap-4 mt-10'>
                      <div class='ml-auto'>
<label htmlFor="my-modal-3" className="btn modal-button btn-link" onClick={()=>setImgmod(rac1)}><img className='w-[470px] h-[340px]' src={rac1}/></label>
                        
                      </div>
                      <div class='mr-auto'>
<label htmlFor="my-modal-3" className="btn modal-button btn-link" onClick={()=>setImgmod(rac2)}><img className='w-[470px] h-[340px]' src={rac2}/></label>
                      
                      </div>
                      <div class='col-span-2 mx-auto'>
<label htmlFor="my-modal-3" className="btn modal-button btn-link" onClick={()=>setImgmod(rac3)}><img className='md:w-[970px] md:h-[680px]' src={rac3} /></label>
                      
                      </div>
                </div>
                {imgmod&&<Imgmodal img1={imgmod} onclose={onclose}/>}
                <button className=' mt-5 md:mt-20 ml-5 lg:ml-56  pb-20 '>Load More</button>
                

            </div>
           
            
        </div>
    );
};

export default Rocket;