import React from 'react';


const Banner = () => {
    return (
        <div className='px-2'>
            <h1 className='text-white font-Oswald text-3xl md:text-7xl md:pt-20 pt-7'>About Us</h1>
         <iframe className='w-full lg:h-[30rem] pt-3 md:pt-10'  src="https://www.youtube.com/embed/PeZ3_fnbaVM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
     </div>
    );
};

export default Banner;