import React,{ useRef } from 'react';
import emailjs from '@emailjs/browser';
import { ToastContainer, toast } from 'react-toastify';

const ContactFrom = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_d96cve2', 'template_sxxd8t8', form.current, 'uwgWYqC_idd4e-MHv')
      .then((result) => {
          console.log(result.text);
          console.log("Email sent")
          toast.success("Email Successfully sent")
        form.current.reset();
      }, (error) => {
          console.log(error.text);
      });
  };
  return (
    
      <div class='pb-60'>
      <div class="bg-[url('/src/Pages/Contact/Images/BG.jpg')] bg-no-repeat bg-cover py-40 ">
        <h1 className='text-white text-center text-xl lg:text-2xl py-20'>Get in touch</h1>
        <form ref={form} onSubmit={sendEmail}>
        <div className='grid grid-cols-2 gap-4 lg:w-1/2 mx-auto px-5 lg:px-0'>
            <input type="text" name="user_name" placeholder="Name" className="input input-bordered w-full lg:w-52 ml-auto" />
            <input type="email" name="email" placeholder="Email" className="input input-bordered w-full lg:w-52" />
            <input type="text" name="message" placeholder="Message" className="input input-bordered w-full lg:w-[27rem] mx-auto col-span-2" />
        </div>
        <button type="submit" className="btn ml-20 lg:ml-[45%] mt-5 bg-[#d74b39] lg:w-50">Send Message</button> 
        </form>
        <ToastContainer></ToastContainer>
      </div>
    </div>
    
  );
};

export default ContactFrom;