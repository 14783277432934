import React from 'react';
import whimg from '../Images/Image.png'
import halfoval from '../Images/halfoval.png'
import woman from '../Images/woman.png'
import img1 from '../Images/team.jpg'
import img2 from '../Images/teamd.jpg'
import img3 from '../Images/teamde.jpg'
import img4 from '../Images/collab.jpg'
const WhoWR = () => {
    return (
        <div className='bg-black relative lg:pb-[60%]'>
            <div className='absolute hidden lg:block' >
                <img className=' -mt-[73%] ml-[93.8%]' src={halfoval} alt=' ' />
            </div>
            <div className='md:mt-20 md:pt-0 pt-5 lg:absolute lg:ml-[10%] px-5 md:px-0'>
                <h1 className='text-4xl text-white font-Opensans font-bold'>Join US</h1>
                <p className='mt-10 text-white text-sm font-Poppins'>Join our Family, Send your resume to<br />
                    career@lakshyaspace.in</p>
                <img src={woman} className='w-[175px] mt-5 h-[456px] mx-auto' />
            </div>
            <div className='flex lg:gap-7 flex-col lg:flex-row justify-center lg:justify-end lg:ml-[35%] py-20 px-10 lg:px-0 lg:absolute lg:z-[9]'>
                <img src={img1} className='rounded w-[314px] h-[319px] mt-60 ' alt=' ' />
                <div className='flex flex-col gap-7 mt-5 lg:mt-0'>
                    <img src={img2} alt=' ' />
                    <img src={img3} alt=' ' /></div>
                <img src={img4} className='rounded w-[314px] h-[319px] mt-5 lg:mt-20 ' alt=' ' />
            </div>
        </div>
    );
};

export default WhoWR;