import React from 'react';
import ContactCubaset from './ContactCubaset';
import FeatureCuba from './FeatureCuba';
import FooterCuba from './FooterCuba';
import TeamCuba from './TeamCuba';

const CubasetNavbar = () => {
    return (
      <div className='bg-black'>
        <FeatureCuba></FeatureCuba>
        <TeamCuba></TeamCuba>
        <ContactCubaset/>
        
      </div>
    );
};

export default CubasetNavbar;