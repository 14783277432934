import React from 'react';
import img from '../Images/Drone.jpg'

const FeatureDrone = () => {
    return (
        <div className='bg-white pb-32 px-5 lg:px-0'>
         <div className='grid grid-cols-1 md:grid-cols-2 md:mx-10 mx-3 gap-10 '>
                    <div>
                        <img src={img} className='h-[300px] md:h-[500px] w-full mt-10' alt="" />
                    </div>
                    <div>
                        <p className='font-abeezee text-sm md:text-2xl mt-3 md:mt-40'>Drones now have many functions, ranging from monitoring climate change to carrying out search operations after natural disasters, photography, filming, and delivering goods. But their most well-known and controversial use is by the military for reconnaissance, surveillance and targeted attacks..</p>
                    </div>
                </div>
    <div className='flex flex-col lg:flex-row justify-between mt-32'>
        <div className='ml-5 md:ml-24' >
            <h1 className='font-Roboto text-xl md:text-2xl'>
                Features
            </h1>
            <h1 className='text-[#EA1C27] text-xl md:text-3xl mt-10 md:mt-20 font-Poppins'>
                We are constantly <br/>working on finding <br/>ways to improve<br/> our products
            </h1>
            </div>
            <div className='grid grid-cols-2 gap-10 md:gap-20 lg:mr-32 mt-10 lg:mt-0'>
                <div>
                    <h1 className=" text-xl md:text-2xl font-Poppins">
                        Art Direction
                        <p className='text-xs'>
                            Am soluta nobis est eligendi <br/>optio cumque nihil impedit<br/> quo minus id quod
                        </p>
                    </h1>
                </div>

                <div>
                <h1 className='text-xl md:text-2xl font-Poppins'>
                        Web Design
                        <p className='text-xs'>
                            Am soluta nobis est eligendi <br/>optio cumque nihil impedit<br/> quo minus id quod
                        </p>
                    </h1>
                </div>

                <div>
                <h1 className='text-xl md:text-2xl font-Poppins'>
                        Marketing
                        <p className='text-xs'>
                            Am soluta nobis est eligendi <br/>optio cumque nihil impedit<br/> quo minus id quod
                        </p>
                    </h1>
                </div>

                <div>
                <h1 className='text-xl md:text-2xl font-Poppins'>
                        Photography
                        <p className='text-xs'>
                            Am soluta nobis est eligendi <br/>optio cumque nihil impedit<br/> quo minus id quod
                        </p>
                    </h1>
                </div>

              
            </div>
        
    </div>
</div>
    );
};

export default FeatureDrone;