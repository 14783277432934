import React,{ useRef } from 'react';
import emailjs from '@emailjs/browser';

const ContactDrone = () => {
    const form = useRef();

    const sendEmail = (e) => {
      e.preventDefault();
  
      emailjs.sendForm('service_d96cve2', 'template_sxxd8t8', form.current, 'uwgWYqC_idd4e-MHv')
        .then((result) => {
            console.log(result.text);
            console.log("Email sent")
        }, (error) => {
            console.log(error.text);
        });
    }
    return (
        <div class='bg-white text-black mt-10 pb-32 px-5 lg:px-0'>
        <div className='pt-5'>
          <h2 className=' lg:ml-32 text-base'>CONTACT</h2>
         
  
          <h1 className='lg:ml-80 mt-5 text-xl md:text-3xl mb-20 font-Montserrat font-mediums'>
          Our doors are always open 24*7, Let’s have a <br/> Meet or a Coffee Together....
          </h1>
  
        </div>
        <div>
        <div>
          <div class="hero-content flex-col lg:flex-row-reverse">
            <div class="text-center lg:text-justify lg:w-2/4">
  
              <p>We are a team of a Enthusiastic Rocket Scientist, Engineer's and Programme's with wide variety of industrial experts, experience and expertise <br /><br />
  
               
                Address: Vemana business incubation center,3rd Block, No.,1, Mahakavi Vemana Rd, Koramangala 3 Block, Koramangala, Bengaluru, Karnataka 560034 <br /><br/>
                Contact No: +91-7019561885<br/><br/>
                Email: info@lakshyaspace.in<br /><br/>
                For Collab: collab@lakshyaspace.in<br/><br/>
                Website: www.lakshyspace.com</p>
            </div>
            <div class="  w-full lg:w-2/4 ">
            <form ref={form} onSubmit={sendEmail}>
              <div class="card-body">
              
                <div >
  
                  <input type="text" name="user_name" placeholder="Name" class="py-2 border-b border-black  bg-transparent md:w-2/4" />
                </div>
                <div >
  
                  <input type="email" name="email" placeholder='Email' class="mt-5 py-2 border-b border-black  bg-transparent md:w-2/4 " />
                </div>
                <div>
  
                  <input type="text" name="message" placeholder='Message'  class="py-2 border-b border-black  bg-transparent mt-12 md:w-2/4" />
  
                </div>
                <div class=" mt-6">
                  <button class=" bg-transparent "><p className='border-b border-black  hover:border hover:text-xl'> Send Message</p></button>
                </div>
               
              </div>
              </form>
            
            </div>
          </div>
        </div>
        
        </div>
        
      </div>
    );
};

export default ContactDrone;