import React from 'react';
import Banner from './Banner/Banner';
import Card from './Card/Card';
import Contact from './Contact/Contact';
import Divider from './Divider/Divider';
import OurTeam from './OurTeam/OurTeam';
import Rocket from './Rocket/Rocket';
import Success from './Success/Success';
import Advisor from './Advisor/Advisor'



const About = () => {
    return (
        <div class='bg-black'>
            <Banner/>
           <Card/>
           <Rocket/>
           <Success/>
           <Divider/>
           <OurTeam/>
           <Advisor/>
           <Contact/>
        </div>
    );
};

export default About;