import React from 'react';
import './Success.css'
const Success = () => {
    return (
        <div class='text-white bg-[#1B212C] pb-40'>
            <h1 class='ml-5 lg:ml-20 pt-60 text-xl font-Montserrat font-medium'>
                Success Stories
            </h1>
            <h1 class='ml-5 lg:ml-20 mt-16 text-base font-Montserrat font-medium'>
                Started in July 2020
                
                    
                   <p className='p-3 '>
                   <li className='mt-3 text-sm md:text-base'>Design and composite 3d printing of  liquid rocket engine- Ekalavya</li>
                    <li className='mt-3 text-sm md:text-base'>Blue print on rocket engine that can use cow dung as a fuel (major)</li>
                    <li className='mt-3 text-sm md:text-base'>Building 3d Printed Sounding rocket. </li>
                    <li className='mt-3 text-sm md:text-base'>Design and developing a Expendable aerial target vehicle</li>
                    <li className='mt-3 text-sm md:text-base'>Design and developed a small nano- satellite- Ardusat</li>
                    <li className='mt-3 text-sm md:text-base'>Official Propulsion wing of Orbitx india aerospace pvt limited</li>
                    <li className='mt-3 text-sm md:text-base'>Extending team from 4 to 40 members</li>
                    <li className='mt-3 text-sm md:text-base'>Worked various projects such as liquid rocket engine,autonomous drones building and delivered a paper plane course to techno anveshana</li>
                    <li className='mt-3 text-sm md:text-base'>Trained more than 100 students by providing free internship</li>
                    <li className='mt-3 text-sm md:text-base'>Conducted a regular webinar/workshop and delivered a to more than 150 students actress india</li>
                   </p>
            
            </h1>
           
           
        </div>
    );
};

export default Success;