import React from 'react';

const Banner3 = () => {
    return (
        <div class=" bg-[url('/src/Pages/Home/Banner/images/01.png')]">

  <div>
      
      <h1 class='text-white text-base md:text-4xl font-Raleway leading-none pt-10 md:pt-24 ml-5 mr-1  md:ml-10 lg:ml-32'>
  Affordable, On-demand and Reliable ride to space with Lakshya Space
       </h1>
       <p class=' text-white text-sm font-VisbyLight font-bold md:text-4xl mt-16 ml-5 md:ml-20 lg:ml-32'>
        Our Service
      </p>
      <h1 class='text-white md:text-4xl font-Raleway leading-none ml-5 md:ml-10 lg:ml-32  pb-10 mt-3'>
      It is not about what we serve, it's about how satisfied you are with our service...</h1>
  </div>
  <div>
  
  </div>
</div>
    );
};

export default Banner3;